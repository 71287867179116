/* eslint-disable import/no-cycle */
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as MenuIcon } from '@assets/icons/ic_burger_menu.svg';
import { DrawerContext } from '@src/layouts/DashboardLayout';
import ProfileMenu from './ProfileMenu';
import { Search } from './ProfileMenu/Search';
import SortDropdown from './ProfileMenu/SortDropdown';

const SIDEBAR_WIDTH = 309;
let CONTAINER_MARGIN = 60;
const OFFSET = SIDEBAR_WIDTH + CONTAINER_MARGIN;

function TopBar(props) {
  const { title, getSortType, getSearchValue, reset, adminPortal, sortType } =
    props;
  const [width, setWidth] = useState(window.visualViewport.width);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const { setDrawerOpen } = useContext(DrawerContext);
  const location = useLocation();
  const shouldDisplayFiltering =
    !location.pathname.includes('dashboard/pending');

  useEffect(() => {
    const handleResize = () => {
      if (!isDesktop) {
        CONTAINER_MARGIN = 32;
        setWidth(window.visualViewport.width - CONTAINER_MARGIN);
        return;
      }
      const newWidth = window.visualViewport.width - OFFSET - CONTAINER_MARGIN;
      setWidth(newWidth);
    };
    // set initial width
    handleResize();
    // add event listener
    window.addEventListener('resize', handleResize);
    // remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      style={{
        maxWidth: `${width}px`,
        position: 'sticky',
        left: isDesktop ? `${OFFSET}px` : '0px',
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={8} container>
          <Hidden mdUp>
            <IconButton
              disableRipple
              style={{
                padding: '4px',
                marginRight: '16px',
              }}
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h1" className="display-1">
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <ProfileMenu
            getSortType={getSortType}
            getSearchValue={getSearchValue}
            reset={reset}
            adminPortal={adminPortal}
            sortType={sortType}
          />
        </Grid>
        <Hidden smUp>
          {shouldDisplayFiltering && (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              style={{
                gap: '16px',
              }}
            >
              <Search getSearchValue={getSearchValue} reset={reset} />
              <SortDropdown
                getSortType={getSortType}
                adminPortal={adminPortal}
                sortType={sortType}
              />
            </Box>
          )}
        </Hidden>
      </Grid>
    </Box>
  );
}

TopBar.defaultProps = {
  getSortType: () => {},
  getSearchValue: () => {},
  reset: () => {},
  adminPortal: false,
  onSort: () => {},
};

export default TopBar;
