import React, { createContext, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Switch, Route } from 'react-router-dom';
import { ReactComponent as Logo } from '@src/img/logo.svg';
import { useScans } from '@hooks/useScans';
import { useUser } from '@hooks/useUser';
import VerifyEmail from '@components/Auth/VerifyEmail';
import { auth } from '@config/firebase';
import { AvatarDashboard } from '../pages/AvatarDashboard';
import Sidebar from './Sidebar';

// lazy components
const AdminDashboard = React.lazy(() =>
  import('@components/AvatarsDashboard/AdminDashboard')
);
const PendingAvatars = React.lazy(() => import('@src/pages/PendingAvatars'));
const SentRequests = React.lazy(() => import('@src/pages/SentRequests'));

const MOBILE_PADDING_TOP = 14;
const TABLET_PADDING_TOP = 24;
const DESKTOP_PADDING_TOP = 44;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.extraLightGrey.main,
    padding: `${MOBILE_PADDING_TOP}px 16px`,
    minHeight: '100vh', // fix for safari
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: `${TABLET_PADDING_TOP}px 24px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${DESKTOP_PADDING_TOP}px 32px`,
    },
  },
}));

const LoadingPage = () => (
  <Grid
    item
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    style={{ height: '100vh' }}
  >
    <Logo />
  </Grid>
);

export const DrawerContext = createContext();

export const DashboardLayout = () => {
  const { data: user } = useUser();
  const { currentUser } = auth;
  const emailNotVerified = currentUser && !currentUser.emailVerified;
  const classes = useStyles({
    emailNotVerified,
  });
  const { unseenScansCount } = useScans(user?.clientId);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const value = useMemo(() => ({ drawerOpen, setDrawerOpen }), [drawerOpen]);

  return (
    <DrawerContext.Provider value={value}>
      <Box display="flex" className={classes.container}>
        <Sidebar
          pendingScansCount={unseenScansCount}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          className={classes.righSideContainer}
        >
          {emailNotVerified && <VerifyEmail />}
          <main className={classes.content}>
            <Switch>
              <Route
                exact
                path="/dashboard/active"
                component={AvatarDashboard}
              />
              <Route
                exact
                path="/dashboard/admin"
                render={() => (
                  <React.Suspense fallback={<LoadingPage />}>
                    <AdminDashboard />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dashboard/pending"
                render={() => (
                  <React.Suspense fallback={<LoadingPage />}>
                    <PendingAvatars />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path="/dashboard/sentRequests"
                render={() => (
                  <React.Suspense fallback={<LoadingPage />}>
                    <SentRequests />
                  </React.Suspense>
                )}
              />
            </Switch>
          </main>
        </Box>
      </Box>
    </DrawerContext.Provider>
  );
};
