import { useState, useRef, useEffect } from 'react';
import {
  makeStyles,
  ClickAwayListener,
  useMediaQuery,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '@assets/icons/search-icon.svg';
import clsx from 'clsx';
import { ReactComponent as ClearIcon } from '@assets/icons/ic_clear-search.svg';
import { IconButton } from './IconButton';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    flexGrow: 1,
  },
  input: {
    padding: '16px 10px',
    borderRadius: '50px',
    border: `1px solid ${theme.palette.lightGrey.main}`,
    backgroundColor: theme.palette.extraLightGrey.main,
    maxHeight: '44px',
    outline: 'none',
    width: '100%',
    maxWidth: 440,
    fontSize: 14,
    caretColor: theme.palette.primary.main,
    paddingLeft: 55,
    [theme.breakpoints.up('sm')]: {
      width: '33vw',
    },
  },
  iconButton: {
    position: 'absolute',
    top: '50%',
    left: 16,
    transform: 'translateY(-50%)',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  clearButton: {
    position: 'absolute',
    top: '50%',
    right: 15,
    transform: 'translateY(-50%)',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    width: 22,
    height: 22,
  },
  searchIconStyles: {
    '& path': {
      fill: '#000',
    },
  },
}));

export const Search = ({ getSearchValue, reset }) => {
  const { container, input, iconButton, clearButton, searchIconStyles } =
    useStyles();
  const [savedValue, setSavedValue] = useState('');
  const inputRef = useRef(null);
  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const [expandSearch, setExpandSearch] = useState(!isNotMobile);

  const handleClickAway = () => {
    if (!savedValue && isNotMobile) {
      setExpandSearch(false);
    }
  };

  const handleReset = () => {
    inputRef.current.value = '';
    reset();
    setSavedValue('');
    getSearchValue(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { search } = e.target.elements;
    const { value } = search;
    getSearchValue(value);
    setSavedValue(value);
    // for performance reasons, for now we will do the search only on
    // submit, but we can do it on every keystroke
    // after handling pagination, and update the user docs so we can mock the autocomplete
    // or search by text `like` which isn't supported in firestore
  };

  useEffect(() => {
    if (expandSearch) {
      inputRef.current.focus();
    }
  }, [expandSearch]);

  return (
    <>
      {expandSearch ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <form className={container} onSubmit={handleSubmit}>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="search by contacts or dates"
              className={clsx('p1', input)}
              defaultValue={savedValue}
              ref={inputRef}
            />
            <button
              className={iconButton}
              type="submit"
              data-testid="search-submit"
            >
              <SearchIcon className={searchIconStyles} />
            </button>
            {savedValue && (
              <button
                className={clearButton}
                type="button"
                onClick={handleReset}
                data-testid="search-clear"
              >
                <ClearIcon />
              </button>
            )}
          </form>
        </ClickAwayListener>
      ) : (
        <IconButton onClick={() => setExpandSearch(true)} testid="search-icon">
          <SearchIcon className={searchIconStyles} />
        </IconButton>
      )}
    </>
  );
};
