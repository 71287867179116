import { useState, lazy } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Drawer,
  List,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
  Snackbar,
  Hidden,
} from '@material-ui/core';
import { useUser } from '@hooks/useUser';
import { Badge } from '@lib/Badge';

import { QRBox } from '@components/QRBox';
import { OpenModalButton } from '@components/Modal/OpenModalButton';
import { ReactComponent as AvatarIcon } from '@assets/icons/ic_avatar.svg';
import { ReactComponent as InboxIcon } from '@assets/icons/ic_inbox.svg';
import { ReactComponent as SendIcon } from '@assets/icons/ic_send.svg';
import { ReactComponent as CopyIcon } from '@assets/icons/ic_copy.svg';
import { ReactComponent as HelpCenterIcon } from '@assets/icons/help-center-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/x.svg';
import { ReactComponent as AdminPortalIcon } from '../img/logo.svg';

// lazy imports
const SendAvatarRequest = lazy(() =>
  import('@components/Modal/SendAvatarRequest')
);

const drawerWidth = 309;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(2 4 74 / 76%)',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  buttonContainer: {
    position: 'relative',
  },
  aside: {
    padding: '24px 16px',
    overflow: 'hidden',
    maxHeight: '100vh',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.up('md')]: {
      padding: 24,
      paddingTop: 44,
    },
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginBottom: 14,
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  sidebarBottom: {
    marginTop: 'auto',
  },
  listItem: {
    margin: 0,
  },
  listItemIcon: {
    minWidth: 'auto',
    marginRight: 14.25,
    position: 'relative',
    '& svg': {
      '& path': {
        width: '100%',
        height: '100%',
      },
    },
  },
  iconModified: {
    marginRight: 19,
  },
  listItemLink: {
    color: theme.palette.black.main,
    padding: 5,
    marginBottom: 12,
    cursor: 'pointer',
    '&:hover': {
      ' & svg': {
        fill: theme.palette.lightBlue.main,
        '& path': {
          fill: theme.palette.lightBlue.main,
        },
      },
      color: theme.palette.lightBlue.main,
      '& .MuiListItemText-root': {
        color: theme.palette.lightBlue.main,
      },
    },
    '&:active': {
      ' & svg': {
        fill: theme.palette.darkBlue.main,
        '& path': {
          fill: theme.palette.darkBlue.main,
        },
      },
      color: theme.palette.darkBlue.main,
      '& .MuiListItemText-root': {
        color: theme.palette.darkBlue.main,
      },
    },
  },
  sendAvatarRequest: {
    borderRadius: 50,
    marginBottom: 14,
    marginTop: 14,
    [theme.breakpoints.up('md')]: {
      marginBottom: 32,
      marginTop: 40,
    },
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: theme.palette.mediumBlue.main,
    color: theme.palette.white.main,
    boxShadow: '0px 10px 24px -8px rgba(10, 36, 128, 0.17)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.lightBlue.main,
      boxShadow: '0px 10px 24px -8px rgba(10, 36, 128, 0.17)',
    },
    '&:active': {
      backgroundColor: theme.palette.darkBlue.main,
      boxShadow: '0px 10px 24px -8px rgba(10, 36, 128, 0.17)',
    },
  },
  sendAvatarRequestIcon: {
    marginLeft: 0,
    marginRight: 8,
  },
  active: {
    '& svg': {
      fill: theme.palette.mediumBlue.main,
      '& path': {
        fill: theme.palette.mediumBlue.main,
      },
    },
    '& .MuiListItemText-root': {
      color: theme.palette.mediumBlue.main,
    },
  },
  helpCenterIcon: {
    paddingTop: 24,
    marginTop: 24,
    borderTop: `1px solid #D9E4FE`,
    '&:hover': {
      ' & svg': {
        fill: theme.palette.mediumBlue.main,
        stroke: theme.palette.mediumBlue.main,
        '& path': {
          fill: theme.palette.mediumBlue.main,
          stroke: theme.palette.mediumBlue.main,
        },
      },
    },
  },
  pendingInvitesNumber: {
    backgroundColor: theme.palette.lightPink.main,
    fontWeight: 900,
    color: theme.palette.white.main,
    fontSize: 12,
    fontFamily: theme.typography.altFontFamily,
    position: 'absolute',
    lineHeight: '16px',
    left: 0,
    top: -9,
    height: 16,
    width: 24,
    textAlign: 'center',
    borderRadius: 40,
  },
  copyIcon: {
    verticalAlign: 'middle',
    marginLeft: 6,
    cursor: 'pointer',
  },
  copyIconWhite: {
    '& path': {
      '&:first-child': {
        fill: '#fff',
      },
      '&:last-child': {
        stroke: '#fff',
      },
    },
  },
  snackbar: {
    width: '100%',
  },
  snackbarBox: {
    padding: '8px 24px 8px 16px',
    borderRadius: 6,
    width: 'auto',
    fontSize: 0,
  },
  snackbarText: {
    float: 'right',
    marginLeft: 14,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 19,
    cursor: 'pointer',
  },
}));

const DrawerContent = ({ setShowToast, pendingScansCount, setDrawerOpen }) => {
  const classes = useStyles();
  const { data: userData } = useUser();
  const {
    orgName = '',
    clientId = '',
    isAdmin = false,
    accountType,
  } = { ...userData };
  return (
    <aside className={classes.aside}>
      <Hidden mdUp>
        <div className={classes.closeIcon}>
          <CloseIcon onClick={() => setDrawerOpen(false)} />
        </div>
      </Hidden>
      <>
        <Badge type={accountType}>{accountType}</Badge>
        <Box>
          <Typography variant="h1" noWrap className="h1">
            {orgName}
          </Typography>
        </Box>

        <Typography
          variant="body1"
          className="p1 dark-grey"
          data-testid="clientId"
        >
          Share code: {clientId}
          <CopyToClipboard text={clientId} onCopy={() => setShowToast(true)}>
            <CopyIcon
              className={classes.copyIcon}
              width="18"
              height="18"
              data-testid="clientId-copy"
            />
          </CopyToClipboard>
        </Typography>
      </>
      <OpenModalButton component={SendAvatarRequest}>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            fullWidth
            className={clsx(classes.sendAvatarRequest, 'h3')}
          >
            Send avatar request
          </Button>
        </div>
      </OpenModalButton>
      <List component="nav" className={classes.nav}>
        <ListItem
          className={classes.listItemLink}
          component={NavLink}
          activeClassName={clsx(classes.active, 'active-link')}
          to="/dashboard/active"
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AvatarIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={clsx('h4', classes.listItem)}
          >
            Avatars
          </ListItemText>
        </ListItem>

        {!isAdmin && (
          <ListItem
            className={classes.listItemLink}
            component={NavLink}
            activeClassName={clsx(classes.active, 'active-link')}
            to="/dashboard/pending"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <InboxIcon />
              {pendingScansCount > 0 ? (
                <Typography
                  variant="body2"
                  className={classes.pendingInvitesNumber}
                  data-testid="pending-avatars-number"
                >
                  {pendingScansCount}
                </Typography>
              ) : null}
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={clsx('h4', classes.listItem)}
            >
              Pending Avatars
            </ListItemText>
          </ListItem>
        )}

        {!isAdmin && (
          <ListItem
            className={classes.listItemLink}
            component={NavLink}
            activeClassName={clsx(classes.active, 'active-link')}
            to="/dashboard/sentRequests"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SendIcon width="24" height="24" />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={clsx('h4', classes.listItem)}
            >
              Sent Requests
            </ListItemText>
          </ListItem>
        )}

        {isAdmin && (
          <>
            <ListItem
              className={classes.listItemLink}
              component={NavLink}
              activeClassName={clsx(classes.active, 'active-link')}
              to="/dashboard/admin"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AdminPortalIcon width="24" height="24" />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={clsx('h4', classes.listItem)}
              >
                Admin Portal
              </ListItemText>
            </ListItem>
          </>
        )}
        <ListItem
          className={clsx(classes.listItemLink, classes.helpCenterIcon)}
          component="a"
          activeClassName={clsx(classes.active, 'active-link')}
          href="https://www.truetoform.fit/help-center"
          target="_blank"
        >
          <ListItemIcon className={classes.listItemIcon}>
            <HelpCenterIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={clsx('h4', classes.listItem)}
          >
            Help Center
          </ListItemText>
        </ListItem>
      </List>
      <QRBox />
    </aside>
  );
};

const Sidebar = ({ pendingScansCount, drawerOpen, setDrawerOpen }) => {
  const classes = useStyles();
  const [showToast, setShowToast] = useState(false);

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, 'bg-white')}
          classes={{
            paper: clsx(classes.drawerPaper, 'bg-white'),
          }}
          open
        >
          <DrawerContent
            setShowToast={setShowToast}
            pendingScansCount={pendingScansCount}
          />
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          className={clsx(classes.drawer, 'bg-white')}
          classes={{
            paper: clsx(classes.drawerPaper, 'bg-white'),
          }}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          implementation="css"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerContent
            setShowToast={setShowToast}
            pendingScansCount={pendingScansCount}
            setDrawerOpen={setDrawerOpen}
          />
        </Drawer>
      </Hidden>
      <Snackbar
        autoHideDuration={4000}
        open={showToast}
        onClose={closeToast}
        className={classes.snackbar}
      >
        <Box className={clsx(classes.snackbarBox, 'bg-extra-dark-grey')}>
          <CopyIcon width="24" height="24" className={classes.copyIconWhite} />
          <Typography
            variant="body1"
            className={clsx('p1', 'white', classes.snackbarText)}
          >
            share code copied
          </Typography>
        </Box>
      </Snackbar>
    </>
  );
};

export default Sidebar;
